<template>
  <b-form @submit="submit" method="POST">
    <input type="hidden" name="ePNAccount" value="0517892" />
    <input type="hidden" name="ReturnToURL" value="http://www.trueframe.com" />
    <input type="hidden" name="BackgroundColor" value="White" />
    <input type="hidden" name="TextColor" value="Black" />
    <input type="hidden" name="ItemQty" value="1" />
    <!--  ----------------------------------------------------------------------  -->
    <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
    <!--  these lines if you wish to test in debug mode.                          -->
    <!--  <input type="hidden" name="debug" value=1>                              -->
    <!--  <input type="hidden" name="debugEmail"                                  -->
    <!--  value="mcasciano@acvauctions.com">                                      -->
    <!--  ----------------------------------------------------------------------  -->
    <template v-if="finished">
      <b-form-row>
        <b-col>
          <h2 class="font-weight-black font-realist text-center mb-6">
            Thank you!
          </h2>
          <p class="text-center">
            We've received your payment, and a receipt has been sent to the
            specified email address.
          </p>
          <p class="text-center">
            If you have any questions, please contact us using the phone number
            listed in the email.
          </p>
        </b-col>
      </b-form-row>
    </template>
    <template v-if="!finished">
      <b-form-row>
        <b-col>
          <b-alert :show="error !== null" variant="danger">
            {{ error }}
          </b-alert>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="6">
          <b-form-group
            label="First Name"
            label-for="first-name"
            label-class="font-weight-bold mb-1 text-uppercase"
            class="mb-6"
            :state="validateState('FirstName')"
            maxlength="40"
            invalid-feedback="This field is required"
          >
            <b-form-input
              class="rounded-sm px-4 py-3 h-12 border-gray-00"
              id="first-name"
              maxlength="40"
              size="20"
              v-model="form.FirstName"
              :state="validateState('FirstName')"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Last Name"
            label-for="last-name"
            label-class="font-weight-bold mb-1 text-uppercase"
            class="mb-6"
            :state="validateState('LastName')"
            invalid-feedback="This field is required"
          >
            <b-form-input
              class="rounded-sm px-4 py-3 h-12 border-gray-00"
              id="last-name"
              maxlength="80"
              size="20"
              v-model="form.LastName"
              :state="validateState('LastName')"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="6">
          <b-form-group
            label="Address"
            label-for="address"
            label-class="font-weight-bold mb-1 text-uppercase"
            class="mb-6"
            :state="validateState('FirstName')"
            maxlength="40"
            invalid-feedback="This field is required"
          >
            <b-form-input
              class="rounded-sm px-4 py-3 h-12 border-gray-00"
              id="address"
              maxlength="40"
              size="20"
              v-model="form.Address"
              :state="validateState('Address')"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="City"
            label-for="city"
            label-class="font-weight-bold mb-1 text-uppercase"
            class="mb-6"
            :state="validateState('City')"
            invalid-feedback="This field is required"
          >
            <b-form-input
              class="rounded-sm px-4 py-3 h-12 border-gray-00"
              id="city"
              maxlength="80"
              size="20"
              v-model="form.City"
              :state="validateState('City')"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="6">
          <b-form-group
            label="State"
            label-for="state"
            label-class="font-weight-bold mb-1 text-uppercase"
            class="mb-6"
            :state="validateState('State')"
            maxlength="40"
            invalid-feedback="This field is required"
          >
            <b-form-select
              :options="usStateValues"
              id="state"
              v-model="form.State"
              :state="validateState('State')"
              class="rounded-sm px-4 py-3 h-12 border-gray-00"
            >
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Zip"
            label-for="zip"
            label-class="font-weight-bold mb-1 text-uppercase"
            class="mb-6"
            :state="validateState('Zip')"
            invalid-feedback="This field is required"
          >
            <b-form-input
              class="rounded-sm px-4 py-3 h-12 border-gray-00"
              id="zip"
              maxlength="80"
              size="20"
              type="number"
              v-model="form.Zip"
              :state="validateState('Zip')"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
            label="Invoice# / VIN"
            label-for="invoice-vin"
            label-class="font-weight-bold mb-1 text-uppercase"
            class="mb-6"
            :state="validateState('InvoiceVin')"
            invalid-feedback="This field is required"
          >
            <b-form-input
              class="rounded-sm px-4 py-3 h-12 border-gray-00"
              id="invoice-vin"
              maxlength="40"
              size="20"
              v-model="form.InvoiceVin"
              :state="validateState('InvoiceVin')"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
            label="Phone"
            label-for="phone"
            label-class="font-weight-bold mb-1 text-uppercase"
            class="mb-6"
            :state="validateState('Phone')"
            maxlength="40"
            invalid-feedback="This field is required"
          >
            <b-form-input
              class="rounded-sm px-4 py-3 h-12 border-gray-00"
              id="email"
              maxlength="40"
              size="20"
              v-model="form.Phone"
              :formatter="formatPhone"
              :state="validateState('Phone')"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
            label="Email"
            label-for="email"
            label-class="font-weight-bold mb-1 text-uppercase"
            class="mb-6"
            :state="validateState('Email')"
            maxlength="40"
            invalid-feedback="This field is required"
          >
            <b-form-input
              class="rounded-sm px-4 py-3 h-12 border-gray-00"
              id="email"
              maxlength="40"
              size="20"
              v-model="form.Email"
              :state="validateState('Email')"
              trim
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row class="border p-2 rounded mb-5">
        <b-col>
          <b-form-row>
            <b-col cols="12">
              <b-form-group
                label="Card Number"
                label-for="card-number"
                label-class="font-weight-bold mb-1 text-uppercase"
                class="mb-6"
                :state="validateState('CardNumber')"
                maxlength="40"
                invalid-feedback="This field is required"
              >
                <b-form-input
                  class="rounded-sm px-4 py-3 h-12 border-gray-00"
                  id="card-number"
                  maxlength="40"
                  size="20"
                  v-model="form.CardNumber"
                  :formatter="formatCardNumber"
                  :state="validateState('CardNumber')"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Card Code"
                label-for="card-code"
                label-class="font-weight-bold mb-1 text-uppercase"
                class="mb-6"
                :state="validateState('CardCode')"
                maxlength="40"
                invalid-feedback="This field is required"
              >
                <b-form-input
                  class="rounded-sm px-4 py-3 h-12 border-gray-00"
                  id="card-code"
                  maxlength="40"
                  placeholder="ex. 224"
                  size="20"
                  v-model="form.CardCode"
                  :state="validateState('CardCode')"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Card Expiration"
                label-for="card-expiration"
                label-class="font-weight-bold mb-1 text-uppercase"
                class="mb-6"
                :state="validateState('CardExpiration')"
                maxlength="40"
                invalid-feedback="This field is required"
              >
                <b-form-input
                  class="rounded-sm px-4 py-3 h-12 border-gray-00"
                  id="card-expiration"
                  maxlength="40"
                  placeholder="ex. 06/24"
                  size="20"
                  v-model="form.CardExpiration"
                  :formatter="formatExpirationDate"
                  :state="validateState('CardExpiration')"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Amount"
                label-for="payment-amount"
                label-class="font-weight-bold mb-1 text-uppercase"
                class="mb-6"
                :state="validateState('PaymentAmount')"
                maxlength="40"
                invalid-feedback="This field is required"
              >
                <b-form-input
                  class="rounded-sm px-4 py-3 h-12 border-gray-00"
                  id="payment-amount"
                  maxlength="40"
                  type="number"
                  size="20"
                  v-model="form.PaymentAmount"
                  :state="validateState('PaymentAmount')"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-col>
        <b-col class="align-items-center d-flex justify-content-center">
          <b-row>
            <b-col class="d-flex justify-content-end">
              <inline-svg
                :class="
                  `${cardNetwork !== 'AMERICAN_EXPRESS' ? 'inactive' : ''}`
                "
                class="icon card"
                :src="require(`@/assets/svg/american-express-card.svg`)"
              />
            </b-col>
            <b-col>
              <inline-svg
                :class="`${cardNetwork !== 'MASTERCARD' ? 'inactive' : ''}`"
                class="icon card"
                :src="require(`@/assets/svg/mastercard-card.svg`)"
              />
            </b-col>
            <div class="w-100"></div>
            <b-col class="d-flex justify-content-end">
              <inline-svg
                :class="`${cardNetwork !== 'DISCOVER' ? 'inactive' : ''}`"
                class="icon card"
                :src="require(`@/assets/svg/discover-card.svg`)"
              />
            </b-col>
            <b-col>
              <inline-svg
                :class="`${cardNetwork !== 'VISA' ? 'inactive' : ''}`"
                class="icon card"
                :src="require(`@/assets/svg/visa-card.svg`)"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col lg="4" offset-lg="4">
          <b-button
            type="submit"
            variant="green-500"
            size="lg"
            :disabled="isSubmitting"
            class="
            font-weight-bold font-realist-normal
            text-xl text-white text-uppercase
            tracking-wider
            w-100
            d-flex
            align-items-center
            justify-content-center
          "
          >
            <template v-if="isSubmitting">
              <b-spinner small class="mr-2 flex-shrink-0"></b-spinner>
              Loading...
            </template>
            <template v-else>Continue</template>
          </b-button>
        </b-col>
      </b-form-row>
    </template>
  </b-form>
</template>
<style scoped lang="scss">
svg.card {
  width: 75px;
  border: 0;
}
svg.inactive {
  opacity: 0.5;
}
</style>
<script>
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mask } from "vue-the-mask";
import { formatCreditCard, formatGeneral } from "cleave-zen";
import InlineSvg from "vue-inline-svg";

const isPhone = value =>
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value);

export default {
  name: "OrderForm",
  components: { InlineSvg },
  mixins: [validationMixin],
  directives: { mask },
  watch: {
    visible() {
      this.$emit("update:heroIsVisible", this.visible);
    }
  },
  data() {
    return {
      isSubmitting: false,
      error: null,
      finished: false,
      usStateValues: [
        { text: "Alabama", value: "AL" },
        { text: "Alaska", value: "AK" },
        { text: "Arizona", value: "AZ" },
        { text: "Arkansas", value: "AR" },
        { text: "California", value: "CA" },
        { text: "Colorado", value: "CO" },
        { text: "Connecticut", value: "CT" },
        { text: "Delaware", value: "DE" },
        { text: "Florida", value: "FL" },
        { text: "Georgia", value: "GA" },
        { text: "Hawaii", value: "HI" },
        { text: "Idaho", value: "ID" },
        { text: "Illinois", value: "IL" },
        { text: "Indiana", value: "IN" },
        { text: "Iowa", value: "IA" },
        { text: "Kansas", value: "KS" },
        { text: "Kentucky", value: "KY" },
        { text: "Louisiana", value: "LA" },
        { text: "Maine", value: "ME" },
        { text: "Maryland", value: "MD" },
        { text: "Massachusetts", value: "MA" },
        { text: "Michigan", value: "MI" },
        { text: "Minnesota", value: "MN" },
        { text: "Mississippi", value: "MS" },
        { text: "Missouri", value: "MO" },
        { text: "Montana", value: "MT" },
        { text: "Nebraska", value: "NE" },
        { text: "Nevada", value: "NV" },
        { text: "New Hampshire", value: "NH" },
        { text: "New Jersey", value: "NJ" },
        { text: "New Mexico", value: "NM" },
        { text: "New York", value: "NY" },
        { text: "North Carolina", value: "NC" },
        { text: "North Dakota", value: "ND" },
        { text: "Ohio", value: "OH" },
        { text: "Oklahoma", value: "OK" },
        { text: "Oregon", value: "OR" },
        { text: "Pennsylvania", value: "PA" },
        { text: "Rhode Island", value: "RI" },
        { text: "South Carolina", value: "SC" },
        { text: "South Dakota", value: "SD" },
        { text: "Tennessee", value: "TN" },
        { text: "Texas", value: "TX" },
        { text: "Utah", value: "UT" },
        { text: "Vermont", value: "VT" },
        { text: "Virginia", value: "VA" },
        { text: "Washington", value: "WA" },
        { text: "West Virginia", value: "WV" },
        { text: "Wisconsin", value: "WI" },
        { text: "Wyoming", value: "WY" }
      ],
      form: {
        CardNumber: "",
        CardExpiration: "",
        CardCode: "",
        FirstName: "",
        LastName: "",
        PaymentAmount: "",
        Email: "",
        City: "",
        State: "",
        Phone: "",
        Zip: "",
        Address: "",
        InvoiceVin: ""
      },
      cardNetwork: null
    };
  },
  validations: {
    form: {
      CardNumber: { required },
      CardCode: { required },
      CardExpiration: { required },
      FirstName: { required },
      LastName: { required },
      Address: { required },
      City: { required },
      State: { required },
      Phone: { required },
      InvoiceVin: { required },
      Zip: { required },
      Email: { required, email },
      PaymentAmount: { required }
    }
  },
  methods: {
    formatExpirationDate(value) {
      return formatGeneral(value, {
        blocks: [2, 2],
        numericOnly: true,
        delimiterLazyShow: true,
        delimiter: "/"
      });
    },
    formatPhone(value) {
      return formatGeneral(value, {
        blocks: [3, 3, 4],
        numericOnly: true,
        delimiterLazyShow: true,
        delimiter: "-"
      });
    },
    formatCardNumber(value) {
      if (value.length) {
        switch (value[0]) {
          case "3":
            this.cardNetwork = "AMERICAN_EXPRESS";
            break;
          case "4":
            this.cardNetwork = "VISA";
            break;
          case "5":
            this.cardNetwork = "MASTERCARD";
            break;
          case "6":
            this.cardNetwork = "DISCOVER";
            break;
          default:
            this.cardNetwork = null;
            break;
        }
      } else {
        this.cardNetwork = null;
      }

      return formatCreditCard(value);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return this.$v.form.$dirty && $dirty ? (!$error ? null : false) : null;
    },

    async submit(e) {
      e.preventDefault();

      this.isSubmitting = true;
      this.error = null;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.isSubmitting = false;
        return;
      }

      const [cardExpMonth, cardExpYear] = this.form.CardExpiration.split("/");

      const response = await new Promise(accept => {
        Accept.dispatchData(
          {
            authData: {
              clientKey: process.env.VUE_APP_ACCEPTJS_PUBLIC_CLIENT_KEY,
              apiLoginID: process.env.VUE_APP_ACCEPTJS_API_KEY
            },
            cardData: {
              cardNumber: this.form.CardNumber.replace(/\s/g, ""),
              month: cardExpMonth,
              year: cardExpYear,
              cardCode: this.form.CardCode,
              fullName: `${this.form.FirstName} ${this.form.LastName}`
            }
          },
          response => {
            accept(response);
          }
        );
      });

      const { messages } = response;
      const { resultCode, message } = messages;

      if (resultCode === "Ok") {
        const { opaqueData } = response;

        try {
          const chargeResponse = await fetch(
            process.env.VUE_APP_AUTHORIZE_NET_CHARGE_LAMBDA_URL,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                email: this.form.Email,
                amount: parseInt(this.form.PaymentAmount),
                nonce: opaqueData,
                firstName: this.form.FirstName,
                invoiceVin: this.form.InvoiceVin,
                lastName: this.form.LastName,
                phone: this.form.Phone,
                address: this.form.Address,
                city: this.form.City,
                state: this.form.State,
                zip: parseInt(this.form.Zip)
              })
            }
          );

          if (!chargeResponse.ok) {
            this.error = `We ran into a problem handling this transaction. Please check your payment information.`;
            this.isSubmitting = false;
            return;
          }

          const responseJson = await chargeResponse.json();

          this.finished = true;
        } catch (e) {
          console.error(e);
          this.error = `We ran into a problem handling this transaction.`;
          this.isSubmitting = false;
        }
      } else {
        this.error = `Transaction failed: ${message[0].text}`;
        this.isSubmitting = false;
      }

      this.isSubmitting = false;
    },
    resetForm() {
      this.form.ItemDesc = "";
      this.form.ItemCost = "";
    }
  }
};
</script>
