<template>
  <div>
    <Hero :visible.sync="visible" :show-cutout="false" class="pb-56 pt-28">
      <b-container class="mt-20">
        <b-row>
          <b-col lg="10" offset-lg="1" class="text-center">
            <h1
              class="
                text-4xl text-md-6xl text-lg-8xl text-white
                font-weight-black font-realist
                tracking-tight
                leading-none
                mb-md-8 mb-4
              "
            >
              Payment
            </h1>
            <div>
              <p
                class="
                  font-weight-medium
                  text-md-2xl text-xl
                  leading-tight
                  text-white
                "
              >
                Have a True360 invoice? Enter your payment here.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </Hero>
    <main>
      <b-container class="mt-n32 mb-24">
        <b-row>
          <b-col md="10" offset-md="1" lg="8" offset-lg="2">
            <div class="bg-white rounded shadow p-12">
              <OrderForm />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
import Hero from "@/components/global/Hero";
import OrderForm from "../components/OrderForm.vue";

export default {
  name: "LearnMore",
  components: {
    Hero,
    OrderForm
  },
  data() {
    return {
      visible: true
    };
  }
};
</script>
